<template>
  <div>
    <span
      v-if="option.theme === 'cazana'"
      class="icon is-small"
    >
      <i
        v-if="option.icon"
        :class="[
          { 'has-text-light': !option.isSelected },
          { 'has-text-info': option.isSelected && $mq === 'mobile' },
          option.icon
        ]"
      />  
      <img
        v-else
        height="16"
        width="16"
        :src="require(`core/img/icons/percayso-logo.svg`)"
      >
    </span>
    <span
     
      :class="[
        option.theme !== 'cazana' 
          ? { 'has-text-weight-bold': option.isSelected, 'has-text-weight-medium': !option.isSelected }
          : 'has-text-weight-bold',
        {
          'has-text-grey-dark':
            $mq === 'mobile' || (option.isSelected && $mq !== 'mobile')
        },
        option.theme === 'cazana' && { 'has-text-white': !option.isSelected && $mq !== 'mobile' }
      ]"
    >{{ option.title }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'DashboardSearchOption',
  props: {
    option: {
      type: Object,
      required: true
    },
  },
  computed: {
    colour() {
      return this.option.isSelected ? 'orange' : 'celadon'
    }
  },
}
</script>
