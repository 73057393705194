var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.option.theme === 'cazana')?_c('span',{staticClass:"icon is-small"},[(_vm.option.icon)?_c('i',{class:[
        { 'has-text-light': !_vm.option.isSelected },
        { 'has-text-info': _vm.option.isSelected && _vm.$mq === 'mobile' },
        _vm.option.icon
      ]}):_c('img',{attrs:{"height":"16","width":"16","src":require(`core/img/icons/percayso-logo.svg`)}})]):_vm._e(),_c('span',{class:[
      _vm.option.theme !== 'cazana' 
        ? { 'has-text-weight-bold': _vm.option.isSelected, 'has-text-weight-medium': !_vm.option.isSelected }
        : 'has-text-weight-bold',
      {
        'has-text-grey-dark':
          _vm.$mq === 'mobile' || (_vm.option.isSelected && _vm.$mq !== 'mobile')
      },
      _vm.option.theme === 'cazana' && { 'has-text-white': !_vm.option.isSelected && _vm.$mq !== 'mobile' }
    ]},[_vm._v(_vm._s(_vm.option.title)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }